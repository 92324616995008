<template>
<div class="add-in-bound">
    <a-card title="增加/修改入库记录">
        <template #extra>
            <a-button @click="$router.go(-1)">返回</a-button>
        </template>
        <div class="aib-item">
            <div class="aib-item-li">
                <span>入库方式：</span>
                <a-select v-model:value="aibState.form.class" style="width: 200px" placeholder="选择入库方式" @change="changeMethod">
                    <a-select-option :value="1">产品入库</a-select-option>
                    <a-select-option :value="2">归还入库</a-select-option>
                    <a-select-option :value="3">农资入库</a-select-option>
                </a-select>
            </div>
            <div class="aib-item-li">
                <span>单据日期：</span>
                <a-date-picker v-model:value="aibState.form.document_date" />
            </div>
        </div>
        <a-table :pagination="false" row-key="sku_id" :columns="aibState.columns" :data-source="aibState.form.data" bordered>
            <template #action="{record,index}">
                <i class="ri-xl ri-top pt ri-add-line" @click="addOrDelProduct('add',index)"></i>
                <i class="ri-xl ri-top pt ri-subtract-line"
                   @click="addOrDelProduct('del',index)"
                   v-if="aibState.form.data.length >= 2"></i>
            </template>
            <template #product="{record,index}">
                <div v-if="record.class_id">
                    <span>{{record.productInfo?.name}}</span>
                    <span>{{record.productInfo?.kd_spec?.model}}</span>
                </div>
                <div v-else>
                    <com-check-product @success="getSelectProduct"
                                       :index="index"
                                       :checked-data="aibState.form.data">
                    </com-check-product>
                </div>
            </template>

            <template #farm="{record,index}">
                <div v-if="record.class_id">
                    <span>{{record.productInfo?.name}}</span>
                </div>
                <div v-else>
                    <com-check-farm-data @success="getSelectProduct"
                                       :index="index"
                                       :checked-data="aibState.form.data">
                    </com-check-farm-data>
                </div>
            </template>
            <template #stock="{record}">
                <div v-if="record.class_id">
                    {{record.productInfo?.stock}}
                </div>
            </template>
            <template #depot_id="{record}">
                <div v-if="record.class_id">
                    {{record.productInfo?.depot?.name }}
                </div>
            </template>
            <template #amount="{record}">
                <div v-if="record.class_id">
                    <a-input v-model:value="record.amount"
                             :addon-after="record.productInfo?.unit?.name"
                             style="width: 120px">
                    </a-input>
                </div>
            </template>
            <template #unit_price="{record}">
                <div v-if="record.class_id">
                    <a-input v-model:value="record.unit_price" addon-after="元" style="width: 120px"></a-input>
                </div>
            </template>
            <template #returnee="{record}">
                <div v-if="record.class_id">
                    <a-input v-model:value="record.returnee" style="width: 120px"></a-input>
                </div>
            </template>
            <template #loss="{record}">
                <div v-if="record.class_id">
                    <a-select v-model:value="record.loss" style="width: 150px" placeholder="物品状态">
                        <a-select-option :value="1">完好无损</a-select-option>
                        <a-select-option :value="2">轻微损耗</a-select-option>
                        <a-select-option :value="3">中度损耗</a-select-option>
                        <a-select-option :value="4">严重损耗</a-select-option>
                    </a-select>
                </div>
            </template>
            <template #total="{record}">
                <div v-if="record.class_id">
                    ￥{{record.amount * record.unit_price}}
                </div>
            </template>
            <template #remark="{record}">
                <div v-if="record.class_id">
                    <a-input v-model:value="record.remark" style="width: 120px"></a-input>
                </div>
            </template>
            <template #desc="{record}">
                <div v-if="record.class_id">
                    <a-input v-model:value="record.desc" style="width: 120px"></a-input>
                </div>
            </template>
            <template #voucher="{record}">
                <div v-if="record.class_id">
                    <span v-if="record.voucher">已上传</span>
                    <com-upload-btn :custom-upload="true" btn-type="link" @uploadChange="e=>record.voucher = e">
                        <a-button type="link">上传凭证</a-button>
                    </com-upload-btn>
                </div>
            </template>
        </a-table>

        <div class="aib-item mt12">
            <a-button type="primary" @click="saveInBound">保存</a-button>
        </div>
    </a-card>
</div>
</template>

<script>
import comUploadBtn from '@/components/public/com-upload-btn'
import comCheckProduct from './com-check-product.vue'
import comCheckFarmData from './com-check-farm-data.vue'
import {useAddInBound, useEditInBound} from '@/models/addons/storage'
import {reactive,toRefs} from "vue";

export default {
    components:{
        comUploadBtn,
        comCheckProduct,
        comCheckFarmData
    },
    setup(){
        let { aibState,getSelectProduct ,addOrDelProduct,saveInBound ,changeMethod} = useAddInBound()

        return {
            aibState,getSelectProduct,addOrDelProduct,saveInBound,changeMethod
        }
    }
}
</script>

<style scoped lang="scss">
.aib-item{
    display:flex;
    margin-bottom: 24px;

    &-li{
        width: 400px;
    }
}
</style>
